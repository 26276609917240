<template>
  <b-card>

    <b-card-title>
      {{ $t('Contents') }}
    </b-card-title>

    <b-card-text>
      <b-row class="d-flex align-items-center justify-content-between">

        <b-col md="12">
          <b-button
            size="sm"
            variant="info"
            @click="createNewContent()"
          >
            {{ $t('Create new content') }}
          </b-button>
        </b-col>

        <b-col
          md="6"
          sm="4"
          class="my-1 mt-2"
        >
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          class="my-1 mt-2"
        >
          <b-form-group
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  {{ $t('clear') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Status') }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="queryFilter.status"
            size="sm"
            :options="statusOptions"
          />
        </b-col>

        <b-col
          md="2"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Start Date') }}</label>
          <b-form-input
            id="filterInput"
            v-model="queryFilter.from"
            type="date"
            size="sm"
          />
        </b-col>

        <b-col
          md="2"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('End Date') }}</label>
          <b-form-input
            id="filterInput"
            v-model="queryFilter.to"
            type="date"
            size="sm"
          />
        </b-col>

        <b-col
          md="3"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Username') }}</label>
          <b-form-input
            id="filterInput"
            v-model="queryFilter.username"
            type="text"
            size="sm"
            :placeholder="$t('Enter Username')"
          />
        </b-col>

        <b-col
          md="3"
          sm="4"
          class="mb-2"
        >
          <label>{{ $t('Content subject') }}</label>
          <b-form-input
            id="filterInput"
            v-model="queryFilter.contentSubject"
            type="text"
            size="sm"
            :placeholder="$t('Enter content subject')"
          />
        </b-col>

        <b-col
          cols="12"
          class="table-container"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            :busy="isLoading"
            :items="filtredContents"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge
                class="font-weight-normal"
                variant="success"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <template #table-busy>
              <div class="text-center">
                <b-spinner label="Loading..." />
              </div>
            </template>

            <template #cell(detail)="data">
              <div
                class="d-flex w-100"
              >
                <div
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="dark"
                    @click="showDetail(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </div>
                <div
                  v-if="data.item.status == 'ChangeMaterial'"
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="dark"
                    @click="changeContentMaterial(data.item)"
                  >
                    <feather-icon icon="CameraIcon" />
                  </b-button>
                </div>
              </div>
            </template>

            <template #cell(actions)="data">
              <div
                v-if="data.item.status == 'WaitingApproval'"
                class="d-flex w-100"
              >
                <div
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="success"
                    class="w-100"
                    @click="confirmApprove(data.item)"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </div>
                <div
                  class="w-50"
                  style="padding: 2px"
                >
                  <b-button
                    size="sm"
                    variant="danger"
                    class="w-100"
                    @click="confirmReject(data.item)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
              <div
                v-if="data.item.status !== 'PublishReady' && 'Archived' && 'Canceled' && 'Publishing'"
                class="w-100"
                style="padding: 2px"
              >
                <b-button
                  size="sm"
                  variant="danger"
                  class="w-100"
                  @click="removeContent(data.item)"
                >
                  {{ $t('removeContent') }}
                </b-button>
              </div>
              <div
                v-if="data.item.status == ('PublishReady' || 'Publishing')"
                class="w-100"
                style="padding: 2px"
              >
                <b-button
                  size="sm"
                  variant="danger"
                  class="w-100"
                  @click="cancelContent(data.item)"
                >
                  {{ $t('cancelThisContent') }}
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>

      </b-row>
    </b-card-text>

    <b-modal
      v-model="showDetailsModal"
      scrollable
      size="lg"
      hide-footer
    >
      <template #modal-header="">
        <span>
          {{ $t('Content Details') }}
        </span>
      </template>

      <div class="d-block">
        <b-row>

          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            />{{ $t('Content subject') }}</label>
            <div> {{ shownItem.contentSubject }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Username') }}</label>
            <div> {{ shownItem.username }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('From') }}</label>
            <div> {{ shownItem.from }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('To') }}</label>
            <div> {{ shownItem.to }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Status') }}</label>
            <div> {{ shownItem.status }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            />{{ $t('Content subject') }}</label>
            <div> {{ shownItem.contentSubject }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            /> {{ $t('Assets Map') }}</label>
            <div> {{ shownItem.assetsMap }} </div>
          </b-col>
          <b-col
            md="6"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            />{{ $t('totalOfScreenForContent') }}</label>
            <div> {{ getScreensCount(shownItem.screens) }} </div>
          </b-col>
          <b-col
            md="12"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
              icon="MapPinIcon"
              size="12"
              class="text-primary"
            />{{ $t('Map') }}</label>
            <div class="map-container">

              <!-- ** map start -->
              <l-map
                ref="mymap"
                :zoom="4"
                :center="center"
                class="map-size"
                style="height:100%;width: 100%;"
              >
                <l-control-fullscreen />
                <l-tile-layer :url="url" />
                <l-marker
                  v-for="(location, i) in shownItem.locations"
                  :key="i"
                  :lat-lng="latLng(location.locationCoordinates.latitude, location.locationCoordinates.longitude)"
                >
                  <l-tooltip :content="isRtl ? location.titleAr : location.titleEn" />
                  <l-popup>
                    <div class="mb-1">
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="MapIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Location ID') }} :
                      </span>
                      <div>{{ location.locationId }}</div>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="CreditCardIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Partner ID Type') }} :
                      </span>
                      <div>{{ location.partnerIdType }}</div>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="UserIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Partner ID') }} :
                      </span>
                      <div>{{ location.partnerId }}</div>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="MapPinIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Latitude & Longitude') }} :
                      </span>
                      <div>
                        {{ location.locationCoordinates.latitude }}, {{ location.locationCoordinates.longitude }}
                      </div>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="DollarSignIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Price Per Hour') }} :
                      </span>
                      <div>
                        <b-badge
                          class="font-weight-normal"
                          variant="success"
                          pill
                        >
                          {{ location.defaultPrice }} <span class="font-weight-bold">{{ $t('SR') }}</span>
                        </b-badge>
                      </div>
                    </div>
                    <div
                      v-if="location.screens.length"
                      class="mb-1"
                    >
                      <span class="font-weight-bold">
                        <feather-icon
                          icon="MonitorIcon"
                          size="12"
                          class="text-primary"
                        /> {{ $t('Screens') }} :
                      </span>
                      <div>
                        <b-badge
                          v-for="(screen, li) in location.screens"
                          :key="li"
                          class="font-weight-normal"
                          variant="primary"
                          pill
                          style="margin: 1px"
                        >
                          {{ screen }}
                        </b-badge>
                      </div>
                    </div>
                  </l-popup>
                </l-marker>
              </l-map>
            <!-- End Map Tag-->
            </div>
          </b-col>

          <b-col
            v-if="shownItem.assets"
            md="12"
            class="mb-2"
          >
            <label class="text-secondary"><feather-icon
                                            icon="MapPinIcon"
                                            size="12"
                                            class="text-primary"
                                          />
              {{ $t('AssetsFiles') }}</label>
            <div v-if="shownItem.assets">
              hdPortrait:   <a
                target="_blank"
                :href="shownItem.assets.hdPortrait"
              >Portrait Image</a>
            </div>
            <div v-if="shownItem.assets">
              hdLandscape:<a
                target="_blank"
                :href="shownItem.assets.hdLandscape"
              > Landscape Image</a>
            </div>
            <b-row>
              <b-col v-if="images.length > 0">
                <!-- directive -->
                <div
                  v-viewer
                  class="images"
                  :options="options"
                >
                  <b-img
                    v-for="src in images"
                    :key="src"
                    thumbnail
                    rounded
                    class="w-50 cursor-pointer"
                    :alt="src"
                    :src="src"
                  />
                </div>
              </b-col>
              <b-col v-if="videoSrc.length > 0">
                <Media
                  v-for="item in videoSrc"
                  :key="item"
                  :kind="'video'"
                  :is-muted="false"
                  :src="item"
                  :autoplay="false"
                  :controls="true"
                  :loop="false"
                  width="100%"
                />

              </b-col>

            </b-row>
          </b-col>

          <b-col
            md="12"
            class="mb-2 table-container"
          >
            <label class="text-secondary"><feather-icon
                                            icon="MapPinIcon"
                                            size="12"
                                            class="text-primary"
                                          />
              {{ $t('Screens') }}</label>
            <b-table
              responsive
              striped
              hover
              :items="shownItem.screens"
            />
          </b-col>

        </b-row>
      </div>
      <b-button
        class="mt-3"
        block
        @click="showDetailsModal = false"
      >
        {{ $t('close') }}
      </b-button>
    </b-modal>

    <b-modal
      id="addLocationModal"
      ref="addLocationModal"
      v-model="isOpenModal"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <span @click="close()">
          <br>
        </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(createContent)">
          <b-row class="d-flex align-items-center justify-content-between">
            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >
              <label>{{ $t('Username') }}</label>

              <validation-provider
                v-slot="validationContext"
                :name="$t('Username')"
                :rules="{ required: true }"
              >
                <b-form-select
                  id="example-input-22344"
                  v-model="username"
                  name="example-input-22344"
                  size="sm"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-2234-live-feedback"
                  @change="selectUsername($event)"
                >
                  <option
                    selected
                    :value="null"
                  >
                    {{ $t('Please select an option') }}
                  </option>
                  <option
                    v-for="option in activatePartners"
                    :key="option.username"
                    :value="option"
                  >
                    {{ option.username }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-2234-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>

              </validation-provider>
            </b-col>

            <b-col
              md="6"
              sm="6"
              class="mb-2"
            >
              <label>{{ $t('All Locations') }}</label>
              <validation-provider
                v-slot="validationContext"
                :name="$t('All Locations')"
                :rules="{ required: true }"
              >
                <b-form-group
                  label-for="country"
                  :state="getValidationState(validationContext)"
                  class="mb-0"
                >
                  <v-select
                    v-model="campaignData.locations"
                    :max-height="50"
                    :clearable="true"
                    label="locationId"
                    :options="locations"
                    placeholder="Select one"
                    track-by="locationId"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preselect-first="true"
                    :reduce="option => option.locationId"
                    :state="getValidationState(validationContext)"
                    class="select-size-sm"
                    @input="checkLocations"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="3"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('Start Date')"
                :rules="{ required: true }"
              >
                <label>{{ $t('Start Date') }}</label>

                <b-form-input
                  id="startDate"
                  v-model="campaignData.from"
                  :state="getValidationState(validationContext)"
                  type="date"
                  size="sm"
                />

                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >

              <validation-provider
                v-slot="validationContext"
                :name="$t('End Date')"
                :rules="{ required: true }"
              >
                <label>{{ $t('End Date') }}</label>

                <b-form-input
                  id="endDate"
                  v-model="campaignData.to"
                  :state="getValidationState(validationContext)"
                  type="date"
                  size="sm"
                />

                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <validation-provider
                v-slot="validationContext"
                :name="$t('Slot Length')"
                :rules="{ required: true }"
              >
                <label>{{ $t('Slot Length') }}</label>
                <b-form-select
                  id="slotLength"
                  v-model="campaignData.slotLength"
                  name="slotLength"
                  size="sm"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <option selected :value="null">
                    {{ $t('Please select an option') }}
                  </option> -->
                  <option
                    v-for="option in slotsLength"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </b-form-select>

                <b-form-invalid-feedback id="input-22-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>

            </b-col>

            <b-col
              md="6"
              sm="12"
              class="mb-2"
            >
              <upload-files
                :file-name="'Hd Portrait'"
                :company-id="campaignData.partnerIdType"
                :user-name="campaignData.username"
                @msg="getUploadHdPortrait($event)"
              />
              <span class="upload-ads-label">{{ $t('portriatLabel') }}</span>
            </b-col>
            <b-col
              md="6"
              sm="12"
              class="mb-2"
            >
              <upload-files
                :file-name="'Hd Landscape'"
                :company-id="campaignData.partnerIdType"
                :user-name="campaignData.username"
                @msg="getUploadHdLandscape($event)"
              />
              <span class="upload-ads-label">{{ $t('landScapeLable') }}</span>
            </b-col>
            <hr>
            <!-- ** link to partners start -->

            <!-- link to partners end ** -->
          </b-row>

          <b-row>
            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <b-button
                class="text-capitalize mt-1"
                :ripple="'rgba(113, 102, 240, 0.15)'"
                type="submit"
                variant="primary"
              >
                {{ $t('Add New Content') }}
              </b-button>
            </b-col>
            <b-col
              md="4"
              sm="4"
              class="mb-2"
            >
              <b-button
                ref="closeModal"
                class="mt-1"
                @click="isOpenModal = false"
              >
                {{ $t('close') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      v-model="showMaterialModal"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <span> {{ $t("contentMaterial") }} </span>
      </template>
      <validation-observer
        ref="observer"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(submitContentMaterial)">
          <div class="d-block">
            <b-row>
              <b-col
                md="6"
                sm="12"
                class="mb-2"
              >
                <div
                  id="profile-pic-demo"
                  class="bg-light pt-3"
                >
                  <VueFileAgent
                    ref="portriatRef"
                    class="profile-pic-upload-block"
                    :multiple="false"
                    :deletable="false"
                    :meta="false"
                    :compact="true"
                    :accept="'image/*'"
                    :help-text="$t('Drag an image file here')"
                    :error-text="{ type: 'Please select an image', }"
                    @select="onSelectPortriat()"
                  >
                    <template v-slot:before-outer />
                    <template v-slot:after-inner>
                      <span class="btn btn-link btn-sm btn-block">{{ $t('Select image file') }}</span>
                    </template>
                    <template v-slot:after-outer>
                      <div>
                        <p>{{ $t('portriatLabel') }}</p>
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          :class="{ 'disabled': !portiatImage }"
                          :disabled="!portiatImage"
                          @click="uploadPortriat()"
                        >
                          <span v-if="!portiatImageUploaded">{{ $t('Upload') }}</span>
                          <b-spinner
                            v-if="portiatImageUploaded"
                            small
                          />
                          <span
                            v-if="portiatImageUploaded"
                            class="sr-only"
                          >Loading...</span>
                          <span v-if="portiatImageUploaded">{{ $t('uploadingSpinner') }}</span>
                        </button>
                        <button
                          type="button"
                          class="btn w-100 mt-1"
                          :class="[portiatImage ? 'btn-danger' : 'btn-secondary']"
                          @click="removePortriatPic()"
                        >{{
                          $t('Remove') }}
                        </button>
                        <div class="clearfix" />
                      </div>
                    </template>
                  </VueFileAgent>
                </div>
              </b-col>
              <b-col
                md="6"
                sm="12"
                class="mb-2"
              >

                <div
                  id="profile-pic-demo"
                  class="bg-light pt-3"
                >
                  <VueFileAgent
                    ref="landScapeRef"
                    class="profile-pic-upload-block"
                    :multiple="false"
                    :deletable="false"
                    :meta="false"
                    :compact="true"
                    :accept="'image/*'"
                    :help-text="$t('Drag an image file here')"
                    :error-text="{ type: 'Please select an image', }"
                    @select="onSelectlandScape()"
                  >
                    <template v-slot:before-outer />
                    <template v-slot:after-inner>
                      <span class="btn btn-link btn-sm btn-block">{{ $t('Select image file') }}</span>
                    </template>
                    <template v-slot:after-outer>
                      <div>
                        <p>{{ $t('landScapeLable') }}</p>
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          :class="{ 'disabled': !landScapeImage }"
                          :disabled="!landScapeImage"
                          @click="uploadLandScape()"
                        ><span
                           v-if="landScapeImageUploaded !== true"
                         >{{ $t('Upload') }}</span>

                          <b-spinner
                            v-if="landScapeImageUploaded"
                            small
                          />
                          <span
                            v-if="landScapeImageUploaded"
                            class="sr-only"
                          >Loading...</span>
                          <span v-if="landScapeImageUploaded"> {{ $t('uploadingSpinner') }}</span>
                        </button>
                        <button
                          type="button"
                          class="btn w-100  mt-1"
                          :class="[landScapeImage ? 'btn-danger' : 'btn-secondary']"
                          @click="removeLandscapePic()"
                        >{{
                          $t('Remove') }}</button>
                        <div class="clearfix" />
                      </div>
                    </template>
                  </VueFileAgent>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-col class="m-2">
            <b-button
              class="mx-1"
              :ripple.400="'rgba(113, 102, 240, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="landscapeMatId === null || portriatMatId === null || portiatImage == false || landScapeImage == false"
            >
              {{ $t("UpdateContentMats") }}
            </b-button>
            <b-button @click="showMaterialModal = false">
              {{ $t("close") }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip,
} from 'vue2-leaflet'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import 'leaflet/dist/leaflet.css'
import LControlFullscreen from 'vue2-leaflet-fullscreen'
import {
  BModal, BSpinner, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Media from '@dongido/vue-viaudio'
import { setTimeout } from 'timers'
import useJwt from '@/auth/useJwt'
import UploadFiles from '@/@core/components/upload/UploadFiles.vue'
import store from '@/store'

export default {
  components: {
    UploadFiles,
    BTable,
    BModal,
    BBadge,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    Media,
    LControlFullscreen,
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    'l-tooltip': LTooltip,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      center: [24.7136, 46.6753],
      PlansValue: '3',
      videoSrc: [],
      portiatImage: false,
      landScapeImage: false,
      landscapeMatId: null,
      portriatMatId: null,
      landScapeImageUploaded: false,
      portiatImageUploaded: false,
      options: {
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: false,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,

      },
      username: null,
      locations: [],
      slotsLength: ['10', '20', '30', '40', '50', '60'],
      contentData: {
        partnerIdType: null,
        username: null,
        locations: null,
        from: '',
        to: '',
        subject: '',
        assets: {
          hdPortrait: '',
          hdLandscape: '',
        },
        slotLength: '10',
      },
      activatePartners: [],
      isOpenModal: false,
      showDetailsModal: false,
      showMaterialModal: false,
      shownItem: {},
      queryFilter: {
        status: '',
        username: '',
        from: '',
        to: '',
        contentSubject: '',
      },
      contents: [],
      isLoading: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      statusOptions: [
        {
          text: 'All', value: '',
        },
        {
          text: 'WaitingMaterial', value: 'WaitingMaterial',
        },
        {
          text: 'ChangeMaterial', value: 'ChangeMaterial',
        },
        {
          text: 'WaitingApproval', value: 'WaitingApproval',
        },
        {
          text: 'PendingPayment', value: 'PendingPayment',
        },
        {
          text: 'PublishReady', value: 'PublishReady',
        },
        {
          text: 'Publishing', value: 'Publishing',
        },
        {
          text: 'Archived', value: 'Archived',
        },
        {
          text: 'Canceled', value: 'Canceled',
        },

      ],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'from',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'contentSubject', label: this.$t('Content subject'), sortable: false },
        { key: 'username', label: this.$t('Username'), sortable: true },
        {
          key: 'from', label: this.$t('Start Date'), sortable: true, sortDirection: 'desc',
        },
        { key: 'to', label: this.$t('End Date'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'Detail', label: this.$t('Detail'), sortable: false },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ],
      images: [],
      currentCampaign: {},
    }
  },
  computed: {
    isRtl() {
      return store.state.appConfig.layout.isRTL
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filtredContents() {
      return this.applyFilter()
    },
  },
  watch: {
    queryFilter: {
      deep: true,
      handler() {
        this.applyFilter()
      },
    },
    isRtl: {
      deep: true,
      handler() {
        this.refreshFields()
        this.refreshFrequencyList()
      },

    },
  },
  created() {
    this.getContents()
    this.getPartners()
    this.getAllLocations()
  },
  mounted() {
    // Set the initial number of items
    this.queryFilter = { ...this.$route.query }

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      console.log('Modal is about to be shown', bvEvent, modalId)
      this.username = null
      this.campaignData = {
        partnerIdType: null,
        username: null,
        locations: null,
        from: '',
        to: '',
        contentSubject: '',
        assets: {
          hdPortrait: '',
          hdLandscape: '',
        },
        slotLength: '10',
      }
    })
  },
  methods: {
    changeContentMaterial(item) {
      console.log(item)
      this.currentCampaign = item
      this.showMaterialModal = true
    },
    async submitContentMaterial() {
      await useJwt
        .put('/adv/api/domain/adv/contents/assets', {
          campaignId: this.currentCampaign.campaignId,
          assets: {
            hdLandscape: this.landscapeMatId,
            hdPortrait: this.portriatMatId,
          },
        })
        .then(res => {
          const updatedContent = res.data.data.contentDetails
          console.log(updatedContent)
          this.showMaterialModal = false
          this.landScapeImageUploaded = false
          this.portiatImageUploaded = false
          this.portiatImage = false
          this.landScapeImage = false
          this.landscapeMatId = null
          this.portriatMatId = null
          this.contents = [updatedContent, ...this.contents.filter(e => e.campaignId !== updatedContent.campaignId)]
          this.currentCampaign = null
          this.$swal({
            icon: 'success',
            title: this.$t('Success'),
            text: this.$t('The Content Info updated successfully'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(err => {
          err.data.data.errors.forEach(err2 => {
            this.showToast(err2)
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    removePortriatPic() {
      this.$refs.portriatRef.deleteFileRecord()
      this.portiatImage = false
    },
    removeLandscapePic() {
      this.$refs.landScapeRef.deleteFileRecord()
      this.landScapeImage = false
    },
    uploadPortriat() {
      this.portiatImageUploaded = true
      console.log('onSelect', this.$refs.portriatRef.fileRecords[0].file)
      const formData = new FormData()
      formData.append('file', this.$refs.portriatRef.fileRecords[0].file)
      // this.uploaded = true
      const CompanyId = this.currentCampaign.clientIdType
      const Username = this.currentCampaign.username
      useJwt.post(`/adv/api/domain/partners/${CompanyId}/${Username}/files`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        }).then(res => {
        console.log('res', res)
        const title = this.$t('Success')
        const msg = this.$t('imageUploadedSuccessfully')
        const state = 'success'
        this.portiatImageUploaded = false
        this.portriatMatId = res.data.fileId
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text: msg,
            variant: state,
          },
        })
      }).catch(err => {
        console.log('err', err)
        const title = this.$t('Oops')
        const msg = this.$t('Something went wrong"')
        const state = 'danger'
        this.portiatImageUploaded = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text: msg,
            variant: state,
          },
        })
      }).finally(() => { this.portiatImageUploaded = false })
    },
    uploadLandScape() {
      this.landScapeImageUploaded = true
      console.log('onSelect', this.$refs.landScapeRef.fileRecords[0].file)
      const formData = new FormData()
      formData.append('file', this.$refs.landScapeRef.fileRecords[0].file)
      // this.uploaded = true
      const CompanyId = this.currentCampaign.clientIdType
      const Username = this.currentCampaign.username
      useJwt.post(`/adv/api/domain/partners/${CompanyId}/${Username}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      }).then(res => {
        const title = this.$t('Success')
        const msg = this.$t('imageUploadedSuccessfully')
        const state = 'success'
        this.landScapeImageUploaded = false
        this.landscapeMatId = res.data.fileId
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text: msg,
            variant: state,
          },
        })
      }).catch(err => {
        console.log('err', err)
        const title = this.$t('Oops')
        const msg = this.$t('Something went wrong"')
        const state = 'danger'
        this.landScapeImageUploaded = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text: msg,
            variant: state,
          },
        })
      }).finally(() => { this.landScapeImageUploaded = false })
    },
    onSelectPortriat() {
      console.log(this.$refs.portriatRef.fileRecords[0].file)
      this.portiatImage = true
    },
    onSelectlandScape() {
      console.log(this.$refs.landScapeRef.fileRecords[0].file)
      this.landScapeImage = true
    },
    resetMapZoom() {
      const map = this.$refs.map.mapObject
      map.setView(this.center, this.zoom)
    },
    latLng(lat, lng) {
      return L.latLng(lat, lng)
    },
    getScreensCount(value) {
      return value ? value?.length : value
    },
    showToast(data) {
      console.log(data)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: Object.keys(data)[0].toString() || '',
          icon: 'BellIcon',
          text: JSON.stringify(Object.values(data)[0]) || '',
          variant: 'danger',
        },
      })
    },
    getUploadHdPortrait($event) {
      console.log($event)
      this.campaignData.assets.hdPortrait = $event.fileId
    },
    getUploadHdLandscape($event) {
      console.log($event)
      this.campaignData.assets.hdLandscape = $event.fileId
    },
    checkLocations() {
    },
    applyFilter() {
      let camps = this.contents
      Object.keys(this.queryFilter).forEach(filterName => {
        if (this.queryFilter[filterName] && this.queryFilter[filterName] !== '') {
          if ([filterName].find(element => element === filterName)) {
            camps = camps.filter(e => e[filterName].toString().toLowerCase().includes(this.queryFilter[filterName].toString().toLowerCase()))
          } else {
            camps = camps.filter(e => e[filterName] === this.queryFilter[filterName])
          }
        }
      })
      this.onFiltered(camps)
      return camps
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    removeContent(item) {
      this.$swal({
        title: this.$t('Are you certain that you wish to delete this content?'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes Approved'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          title: 'swal-custom-title',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.delete(`adv/api/domain/adv/content/${item.campaignId}`)
            .then(res => {
              console.log(res)
              this.contents = [...this.contents.filter(e => e.campaignId !== item.campaignId)]
              this.$swal({
                icon: 'success',
                title: this.$t('Approved'),
                text: this.$t('Content has been removed'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t('Something went wrong'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    cancelContent(item) {
      console.debug(item.campaignId)
      this.$swal({
        title: this.$t('Are you certain that you wish to cancel this content?'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes Approved'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          title: 'swal-custom-title',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.delete(`adv/api/domain/adv/content/${item.campaignId}/stop`)
            .then(res => {
              console.log(res)
              this.contents = [...this.contents.filter(e => e.campaignId !== item.campaignId)]
              this.$swal({
                icon: 'success',
                title: this.$t('Approved'),
                text: this.$t('Content has been cancelled'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t('Something went wrong'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    confirmApprove(content) {
      this.$swal({
        title: this.$t('Are you sure'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes Approved'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const payload = {
            campaignId: content.campaignId,
            approvedBy: 'ahmed',
          }
          useJwt.put('/adv/api/domain/adv/contents/approve', payload)
            .then(res => {
              console.log(res)
              const updatedContent = res.data.data.contentDetails
              this.contents = [updatedContent, ...this.contents.filter(e => e.campaignId !== updatedContent.campaignId)]
              this.$swal({
                icon: 'success',
                title: this.$t('Approved'),
                text: this.$t('Content has been approved'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(err => {
              console.error(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t('Something went wrong'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    confirmReject(content) {
      this.$swal({
        title: this.$t('Are you sure'),
        text: this.$t('You wont be able to revert this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes Reject'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const payload = {
            campaignId: content.campaignId,
            rejectedBy: 'ahmed',
            rejectionReason: 'Reason 1',
          }
          useJwt.put('/adv/api/domain/adv/contents/reject', payload)
            .then(res => {
              console.debug(res)
              const updatedContent = res.data.data.contentDetails
              this.contents = [updatedContent, ...this.contents.filter(e => e.campaignId !== updatedContent.campaignId)]
              this.$swal({
                icon: 'success',
                title: this.$t('Approved'),
                text: this.$t('Content has been Rejected'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(err => {
              console.error(err)
              this.$swal({
                icon: 'error',
                title: this.$t('Oops'),
                text: this.$t('Something went wrong'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    getContents() {
      console.log('getContents')
      this.isLoading = true
      useJwt.get('/qadv/api/domain/adv/contents')
        .then(({ data }) => {
          console.log(data)
          this.contents = data.data.contents
          this.totalRows = this.contents.length
          console.log(this.contents)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showDetail(object) {
      console.log(object, 'object')
      this.shownItem = { ...object }
      this.images = []
      this.videoSrc = []
      if (this.shownItem?.assets) {
        if (this.shownItem.assets.hdPortrait.endsWith('.mp4')) {
          this.videoSrc.push(this.shownItem.assets.hdPortrait, this.shownItem.assets.hdLandscape)
        } else {
          this.images.push(this.shownItem.assets.hdPortrait, this.shownItem.assets.hdLandscape)
        }
      }
      this.showDetailsModal = true

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.mymap.mapObject.invalidateSize()
        }, 100)
      })
    },
    getPartners() {
      this.isLoading = true
      this.activatePartners = []
      useJwt
        .get('/qpartner/api/domain/partners')
        .then(({ data }) => {
          this.activatePartners = [...data?.data?.activatePartners]
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })

      this.data = JSON.parse(localStorage.getItem('userInfo'))
      console.log(this.data.partnerInfo)
      this.activatePartners = [{ username: this.data.partnerInfo.data.username, partnerIdType: 'CompanyId', partnerInfo: this.data.partnerInfo }, ...this.activatePartners]
      console.log(this.activatePartners)
    },
    createNewContent() {
      this.isOpenModal = true
    },
    createContent() {
      this.campaignData.slotLength = Number(this.campaignData.slotLength)
      this.isLoading = true
      useJwt
        .post('/adv/api/domain/adv/contents', this.campaignData)
        .then(({ data }) => {
          console.debug(data)
          if (data.status === 'Success') {
            const newContent = data.data.contentDetails
            console.debug(newContent)
            this.contents = [newContent, ...this.contents]
            console.debug(this.contents)
            this.$swal({
              icon: 'success',
              title: this.$t('Success'),
              text: this.$t('The Content Created successfully'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(e => {
              console.error(e)
              this.$refs.closeModal.click()
            })
          }
        })
        .catch(err => {
          err.data.data.errors.forEach(error => {
            this.showToast(error)
          })
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    getAllLocations() {
      this.isLoading = true
      useJwt
        .get('/qasset/api/domain/locations')
        .then(({ data }) => {
          this.locations = data.data.locations
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    selectUsername(value) {
      this.campaignData.username = value.username
      this.campaignData.partnerIdType = value.partnerIdType
    },
    refreshFields() {
      this.fields = [
        { key: 'contentSubject', label: this.$t('Content subject'), sortable: true },
        { key: 'username', label: this.$t('Username'), sortable: true },
        { key: 'from', label: this.$t('Start Date'), sortable: true },
        { key: 'to', label: this.$t('End Date'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'Detail', label: this.$t('Detail'), sortable: false },
        { key: 'actions', label: this.$t('Actions'), sortable: false },
      ]
    },
  },
}
</script>
<style scoped  lang="scss">
.map-container {
  width: 100%;
    height: 400px;
}
  .images {
    display: flex;
    justify-content: center;
    height: 300px;
    gap: 4px;
   }
.upload-ads-label {
  font-size: 13px;
}
#profile-pic-demo .drop-help-text {
  display: none;
}

#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}

#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #AAA;
}

#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}
</style>
